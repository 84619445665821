<template>
	<Transition name="slide-fade" appear>
        <div class="w-A4W m-auto bg-white  container"> 
            <div class="rounded shadow-t3 items-center relative md:mt-0">


                <div class="w-full p-8 flex justify-between shadow-t3">
                    <h2 class="text-xl text-t3 font-bold sm:text-3xl dark:text-white">
                        Report Preview
                    </h2>
                    <div class="relative px-2">
                        <label for="customRange3" class="form-label">Change Size</label>
                        <input
                            type="range"
                            class="
                            form-range
                            appearance-none
                            w-full
                            h-6
                            p-0
                            bg-transparent
                            focus:outline-none focus:ring-0 focus:shadow-none
                            "
                            min="0"
                            max="5"
                            step="1"
                            id="customRange3"
                            v-model="stepper"
                        />
                        </div>
                    <div class="flex gap-3 items-center">
                        <button @click="exportToPDF()" class=" w-48 m-auto font-medium bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block" >Download</button>
                        
                    </div>
                </div>

                <div class="w-full m-auto text-center relative" id="certificate">
                    <div class="bg-t3">
                            <!-- <img style="max-width: 100px;" src="../assets/white-transparent-logo.svg"> -->
                        
                        <div class="flex justify-between w-full p-4 items-center">
                            <div>
                                <h1 class="font-semibold text-xl text-white p-4">
                                    ELP Report
                                </h1>
                            </div>
                            <div class="text-white text-center flex flex-col items-center text-xs justify-center space-x-2 px-4 ">
                                <img  class="mb-1 w-32" src="../assets/white-transparent-logo.svg">
                                <p>www.t3-elp.com</p>
                            </div>

                        </div>
                    </div>
                    <div class="px-8 ">
                        <div class="flex justify-between items-center  py-8 ">
                        <div class="flex items-center  text-left"  >
                        <div>
                            <img  class=" h-24 w-24 rounded ring-2 ring-white" :src="assessment.customer_image"/>
                        </div>
                        <div class=" p-4">
                            <h1 class=" mb-2 font-bold text-t3 " :class="textSize()" >{{assessment.certificate_name}}</h1>
                            <h1 class="text-md font-medium text-gray-400 " >{{assessment.customer_id}}</h1>
                        </div>

                        </div>
                        <div class="text-left">
                            <p class="text-md text-t3 " ><span class="text-gray-400 mr-2 ">ELP Level:</span>{{trueLevel}}</p>
                            <p class="text-md text-t3 " ><span class="text-gray-400 mr-2 ">Date & Time:</span>{{new Date(assessment.date).toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})}}-{{assessment.start_time}}</p>
                            <p class="text-md text-t3 " v-if="addresses.find(a => a.id === assessment.location)" ><span class="text-gray-400 mr-2 " >Location:</span>{{addresses.find(a => a.id === assessment.location).name}}</p>
                        </div>
                        </div>
                        <div class="text-left py-10 border-t">
                            <div class="flex justify-between items-center">
                                <div>
                                    <h1 class="text-t3 font-semibold text-xl mb-6">English Language Expert (ELE)</h1>
                                    <!-- <p v-if="assessmentELE" class="text-gray-400 text-md ">{{assessmentELE.full_name}}</p> -->
                                </div>
                                <h1 class="text-t3 text-sm">Awarded level <span class="font-semibold">{{reportE.level}}</span></h1>
                            </div>
                            <p class="text-gray-400 text-sm text-justify">{{reportE.comment}}</p>
                            
                        </div>
                        <div class="text-left py-10 border-t">
                            <div class="flex justify-between items-center">
                                <div >
                                    <h1 class="text-t3 font-semibold text-xl mb-6">Operational Expert (OPE)</h1>
                                    <!-- <p v-if="assessmentOPE" class="text-gray-400 text-md ">{{assessmentOPE.full_name}}</p> -->
                                </div>
                                <h1 class="text-t3 text-sm">Awarded level <span class="font-semibold">{{reportO.level}}</span></h1>
                            </div>
                            <p class="text-gray-400 text-sm text-justify">{{reportO.comment}}</p>
                        </div>
                    </div>
                    
                    
                </div>

            

            </div>
        </div>
    </Transition>
</template>

<script>
// import { watchEffect } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
// import jsPDF from 'jspdf'
import html2pdf from "html2pdf.js";
//  import Swal from 'sweetalert2';
// import ReportServices from '@/services/ReportServices'
//  import QuestionsServices from '../services/QuestionsServices';

    export default {
        props:{
            id:{
                type:Number
            }
        },
        data() {
			return {
                long:false,
                viewBackground:true,
                stepper:'3'
			}
		},

        methods: {
        ...mapActions({
            getreportELE: 'Assessments/getELEReport',
				getreportOPE: 'Assessments/getOPEReport',
			getassessment: 'Assessments/getAssessment',
            getaddresses:'Settings/getAllAddresses',
			getcustomers: 'Users/getAllCustomers',
			getquestions: 'Questions/getAllQuestions',
			getsample: 'Samples/getSample',
			getsettings: 'Settings/getAllSettings',
            createquestion: 'Questions/createQuestion',
        }),
        textSize(){
        let size = "text-lg"
        switch(this.stepper){
            case '0':
                size = "text-xs mb-4"
                break;
            case '1':
                size = "text-sm mb-3"
                break;
            case '2':
                size = "text-base mb-2"
                break;
            case '3':
                size = "text-lg mb-1"
                break;
            case '4':
                size = "text-xl mb-1"
                break;
            case '5':
                size = "text-2xl"
                break;
            
        }
        return size
      },
        exportToPDF() {
            const element = document.getElementById('certificate');
            const opt = {
                margin:       0,
                filename:     this.assessment.customer_name+'-ELP-Report.pdf',
                image:        { type: 'jpeg', quality: 1.0 },
                html2canvas:  { scale: 3,useCORS: true },
                jsPDF:        { orientation: 'portrait' }
            };
            html2pdf().set(opt).from(element).save()
        }
        
         },

        computed: {
        ...mapState({
            reportO: state => state.Assessments.opereport,
			reportE: state => state.Assessments.elereport,
            addresses: state => state.Settings.addresses,
			customers: state => state.Users.customers,
			assessment: state => state.Assessments.assessment,
			settings: state => state.Settings.settings,
        }),
        ...mapGetters('Users', [
        'customerOnly',
        'staffOnly',
        "ELE",
        "OPE"
      ]),
      trueLevel(){
        let sus=""
        switch(this.assessment.final_level){
            case 6:
                sus = "Expert Level (6)"
                break;
            case 5:
                sus = "Extended Level (5)"
                break;
            case 4:
                sus = "Operational Level (4)"
                break;
            case 3:
                sus = "Pre-operational Level (3)"
                break;
            case 2:
                sus = "Elementary Level (2)"
                break;
            case 1:
                sus = "Pre-elementary Level (1)"
                break;
        }
        return sus
      },
              assessmentCustomer() {
				return this.customers.find(cus => cus.id === this.assessment.customer)
			},
            assessmentELE() {
				return this.staffOnly.find(cus => cus.id === this.assessment.ele)
			},
            assessmentOPE() {
				return this.staffOnly.find(cus => cus.id === this.assessment.ope)
			},
            todaydate(){
                let date = new Date(this.assessment.completion_time)
                
                return date.toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})
            },
            expirydate(){
                let date = new Date(this.assessment.completion_time)
                        switch(this.assessment.final_level){
                case 6:
                    date= "Lifetime"
                    break;
                case 5:
                    date.setFullYear(date.getFullYear() + 6);
                    date.setDate(date.getDate()-1)
                    break;
                case 4:
                    date.setFullYear(date.getFullYear() + 3);
                    date.setDate(date.getDate()-1)
                    break;
                }
                if(this.assessment.final_level === 6){
                    return date
                }
                else{
                    return date.toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})
                }
                
            }
		},

        created() {
			this.getassessment(this.id).then(()=>{
                if(this.assessment.certificate_name.length > 25){
                    this.long = true
                } 
            })
			this.getsettings()
			this.getcustomers()
            this.getaddresses()		
            this.getreportELE(this.id)
			this.getreportOPE(this.id)		
	}
    }
</script>

<style>
@page {
    margin: 0;
    padding:0;
    overflow: hidden;
    size:portrait;
    height:100%;
}



</style>